import {NavLink, Link, useLocation } from 'react-router-dom';
import {useEffect, useState} from 'react';
import UserService  from "../services/UserService";
import AssistantService  from "../services/AssistantService";
import Logo from "../images/Logo-overview.png";
import { useTranslation } from 'react-i18next';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle';
function Sidebar() {
   const location = useLocation();
  const { t } = useTranslation();
  const [user, setUser] =  useState("");
  const [userRole, setUserRole] =  useState("");
  const [organizationList, setOrganizationList] =  useState();
  const [organizationID, setOrganizationID] = useState(parseInt(window.sessionStorage.getItem('organization_id'), 10));
  const [assistantList, setAssistantList] =  useState([]);

  const switchOrganization = (event) => {
    //console.log("event.target", event.target)
    const orgID = event.target.value;
    const selectedOrgName = event.target.selectedOptions[0].text; 
    window.sessionStorage.setItem('organization_id', orgID)
    window.sessionStorage.setItem('organization_name', selectedOrgName)
    window.location.href = '/overview'
  }
  //console.log("organization_id", window.sessionStorage.getItem('organization_id'))
  useEffect(() => {
    const accessToken = window.sessionStorage.getItem('email');
    setUserRole(window.sessionStorage.getItem('userrole'));
    
    UserService.GetOrganizationList().then(
      (response) => {
        setOrganizationList(response.data.organizations)
        console.log(response.data.organizations)
      }
    ).catch(
      (error) => {
        console.log("Error getting GetOrganization", error)
      }
    )

    UserService.getUserFromToken(accessToken).then (
    (user) => {
      setUser(user);
    });

    AssistantService.assistantsList(organizationID).then (
    (assistants) => {
      //console.log(assistants);
      setAssistantList(assistants.data);
    });

    
  }, [organizationID])
  useEffect(() => {
    // Collapse the sidebar whenever the route changes
    const sidebar = document.getElementById("sidebarMenu");
    if (sidebar) {
      const bootstrapCollapse = bootstrap.Collapse.getInstance(sidebar);
      if (bootstrapCollapse) {
        bootstrapCollapse.hide(); // Collapse the menu
      }
    }
  }, [location.pathname]);
   const [expandedIndex, setExpandedIndex] = useState(0);
   const handleToggle = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };
 
	return (
        <>
          <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block sidebar collapse fm-sidemenu-color">
                  
            <div className="col-md-12 col-lg-12 d-md-block fm-sidebar-logo" align="center">
              <div className="row align-middle table-responsive" align="center">
                  <div className="col-12">
                        <img src={Logo} alt={Logo} className="img-fluid" />
                    </div>
                </div>
            </div>
            
            <div className="col-md-12 col-lg-12 d-md-block p-2 fm-sidebar-user" align="center">
              <div className="row align-middle table-responsive" align="center">
                  <div className="col-12">
                        <table className="table table-sm align-middle" border="0">
                          <tr className="align-middle">
                            {/*<td className="align-middle" width="45"><img src={UserAvatar} /></td>
                            <td className="align-middle"><div style={{height: '40px', width: '1px', borderLeft: '2px solid rgb(104, 107, 110)'}}></div></td> */}
                            <td width="40">&nbsp;</td>
                            <td className="align-middle"><b>{user.name}</b></td>
                            <td>&nbsp;</td>
                            <td className="align-middle" align="right">
                                {userRole == 1 || userRole == 2 ? 
                                <select className="organizationSwitch" onChange={switchOrganization}>
                                  {organizationList ? organizationList.map((value, key) => {
                                    return <>
                                    <option value={value.id} selected={organizationID===value.id ? "selected" : ""} >{value.name}</option>
                                    </>
                                  }) : ""}
                                </select>
                                : "" }
                              
                              
                            {/*<img src={SettingIcon} />*/}</td>
                            {/*<td className="align-middle" width="40"><img src={ProfileEdit} /></td>*/}
                          </tr>
                        </table>
                    </div>
                </div>
            </div>
                    
                    
            <div className="position-sticky pt-3">
              <ul className="nav flex-column">
                <li className="nav-item">
                  <Link  className="nav-link"  to={"/overview"}>
                    <div className="menu-icon"><i className="bi bi-speedometer2"></i></div>
                    <div className="menu-name">
                        <b>{t('companyinformation')}</b>
                        
                    </div>
                    
                   
                  </Link>
                </li>
                {userRole == 1 ? (
                    <li className="nav-item">
                      <Link  className="nav-link" to={"/tools"}>
                        <div className="menu-icon"><i className="bi bi-lock"></i></div>
                        <div className="menu-name">
                            <b>{t('tools')}</b>
                            
                        </div>
                        
                      </Link>
                    </li>
                ) : ''}
                
                {/*<li className="nav-item">
                  <a className="nav-link" href="#">
                    <i className="bi bi-lock"></i>
                    {t('virtualassistants')}
                  </a>
                </li> */}
                 
                {assistantList.map((val, index) => (
                  <li key={index} className={`nav-item ${expandedIndex === index ? 'expanded' : ''}`}>
                      <a
                          className="nav-link"
                          href="#"
                          onClick={() => handleToggle(index)}
                      >
                          <div className="assistantMenu">
                              <div className="toggleIcon">
                                  {expandedIndex === index ? (
                                      <i className="bi bi-chevron-up"></i>
                                  ) : (
                                      <i className="bi bi-chevron-down"></i>
                                  )}
                              </div>
                              <div className="assistantIcon"><i className="bi bi-search"></i></div>
                              <div className="assistantNameDesc">
                                  <b>{t(val.slug)}</b>
                                  <br />
                                  <span className="assistantDescription">{t(val.description_slug)}</span>
                              </div>
                          </div>
                      </a>
                      <ul className="nav-item-sub-item">
                          {userRole == 1 ? (
                              <li className="nav-item">
                                  <NavLink className="nav-link" to={`/${val.slug}/job-instruction`}>
                                      <i className="bi bi-lock"></i>
                                      {t('jobinstruction')}
                                  </NavLink>
                              </li>
                          ) : ''}
                          <li className="nav-item">
                              <NavLink className="nav-link" to={`/${val.slug}/overview`}>
                                  <i className="bi bi-speedometer2"></i>
                                  {t('overview')}
                              </NavLink>
                          </li>
                          <li className="nav-item">
                              <NavLink className="nav-link" to={`/${val.slug}/job-description`}>
                                  <i className="bi bi-card-checklist"></i>
                                  {t('jobdescription')}
                              </NavLink>
                          </li>
                          <li className="nav-item">
                              <NavLink className="nav-link" to={`/${val.slug}/training`}>
                                  <i className="bi bi-clipboard-data"></i>
                                  {t('training')}
                              </NavLink>
                          </li>
                          <li  className="nav-item">
                              <NavLink className="nav-link" to={`/${val.slug}/work-schedule`}>
                                  <i className="bi bi-lightbulb"></i>
                                  {t('workschedule')}
                                </NavLink>
                              
                          </li>
                          <li className="nav-item">
                              <NavLink className="nav-link" to={`/${val.slug}/tasks`}>
                                  <i className="bi bi-compass"></i>
                                  {t('completedtask')}
                              </NavLink>
                          </li>
                      </ul>
                  </li>
              ))}
                {/* assistantList.map((val, key) => {
                  return <>
                    <li className="nav-item" key={key}>
                    <a className="nav-link" href="#">
                      <div>
                        <div className="assistantIcon"><i className="bi bi-search"></i></div>
                        <div className="assistantNameDesc"> 
                            <b>{val.name}</b>
                            <br />
                            <span  className="assistantDescription">{val.description}</span>
                            </div>
                      </div>
                      
                      
                    </a>
                    <ul className="nav-item-sub-item">
                      {userRole == 1 ? 
                        <>
                      <li class="nav-item">
                        <NavLink className="nav-link" to={"/"+val.slug+"/job-instruction"}>
                        <i className="bi bi-sliders"></i>
                          {t('tool')}
                        </NavLink>
                        
                      </li>
                      </> : ''}
                      <li className="nav-item">
                        <NavLink className="nav-link" to={"/"+val.slug+"/overview"}>
                        <i className="bi bi-speedometer2"></i>
                          {t('overview')}
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink className="nav-link" to={"/"+val.slug+"/job-description"}>
                        <i className="bi bi-card-checklist"></i>
                          {t('jobdescription')}
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink className="nav-link" to={"/"+val.slug+"/training"}>
                        <i className="bi bi-clipboard-data"></i>
                          {t('training')}
                        </NavLink>
                      </li>
                      <li className="nav-item" >
                        <NavLink  className="nav-link disabled disabled-link" to={"/"+val.slug+"/work-schedule"}>
                        <i className="bi bi-lightbulb"></i>
                          {t('workschedule')}
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink className="nav-link" to={"/"+val.slug+"/tasks"}>
                        <i className="bi bi-compass"></i>
                          {t('completedtask')}
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  </>
                }) */}
                {/*<li className="nav-item">
                  <a className="nav-link" href="#">
                  <i className="bi bi-search"></i>
                    SEO
                  </a>
                  <ul className="nav-item-sub-item">
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/job-description">
                      <i className="bi bi-card-checklist"></i>
                        {t('jobdescription')}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/training">
                      <i className="bi bi-clipboard-data"></i>
                        {t('training')}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/work-schedule">
                      <i className="bi bi-lightbulb"></i>
                        {t('workschedule')}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/tasks">
                      <i className="bi bi-compass"></i>
                        {t('completedtask')}
                      </NavLink>
                    </li>
                  </ul>
                </li>
                
                <li className="nav-item">
                  <a className="nav-link" href="#">
                  <i className="bi bi-search"></i>
                    SEO Meta
                  </a>
                </li>
                */}
              </ul>

            </div>
          </nav>

        </>
        )
}
export default Sidebar;
import {Link, useNavigate, useParams  } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import AuthService from "../services/AuthService";
import PromptService from "../services/PromptService";
import AssistantService from "../services/AssistantService";
import "../styles/bootstrap.min.css";
import CreditBar from "./CreditBar";
import TopBar from "./Topbar";
import SideBar from "./Sidebar";
import UserAvatar  from "../images/user-avatar.png";
import OurValues  from "../images/our-values.png";
import ToneOfVoice  from "../images/tone-voice.png";
import Language  from "../images/language.png";
import JobResult  from "../images/job-result.png";
import Feedback  from "../images/feedback.png";
import ThumbUp  from "../images/thumb-up.png";
import RestoreIcon  from "../images/restore.png";
import Notification  from "../images/notifications_none.png";
import Theme  from "../images/moon-solid.png";
import Information  from "../images/info_outline.png";
import { useTranslation } from 'react-i18next';

function JobHistory() {
  const { appslug } = useParams();
  let navigate  = useNavigate();
  document.body.style.backgroundColor = '#FFFFFF';
  const { t } = useTranslation();
  const [organizationID, setOrganizationID] = useState(parseInt(window.sessionStorage.getItem('organization_id'), 10));
  const [promptList, setPromptList] = useState([]);
  const [totalPrompts, setTotalPrompts] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [assistantID, setAssistantID] = useState();
  const [totalProgressPages, setTotalProgressPages] = useState(1);
  const [totalCredit, setTotalCredit] = useState();
  const [creditUsed, setCreditUsed] = useState();
  const [totalGroup, setTotalGroup] = useState();
  const [groupData, setGroupData] = useState({});
  const [groupsList, setGroupsList] = useState();
  useEffect(() => {
    AssistantService.getAssistantIDBySlug(appslug, organizationID).then(
      (assistresponse) => {
        setAssistantID(assistresponse.assistantID);
        PromptService.promptHistory(assistresponse.assistantID, organizationID, pageNumber).then(
        (response) => { 
          setTotalGroup(response.total_records);
          setTotalProgressPages(response.total_pages);
          setGroupsList(response.groups);
          response.groups.forEach(gro => {
            fetchGroupData(gro.group);
          });
          

          /*console.log(response.answers);
          setTotalPrompts(response.total_records);
          setTotalProgressPages(response.total_pages)
          setPromptList(response.answers);*/
        }).catch(
          (error) => {
            console.log(error);
          }
        );
      }
    )
    /*PromptService.getPromptByAssisstant("1").then(
    (response) => { 
      setPromptList(response.prompts);
    }).catch(
      (error) => {
        console.log(error);
      }
    );*/

    AssistantService.getAssistantIDBySlug(appslug, organizationID).then(
      (assistresponse) => {
        setTotalCredit(assistresponse.credits)
        setCreditUsed(assistresponse.consumed)
      }
    )
  }, [appslug, pageNumber])

   const fetchGroupData = async (groupID) => {
    try {
      PromptService.fetchGroupData(assistantID, organizationID, groupID).then(
      (response) => {
        console.log(response);
        setGroupData(prevData => ({ ...prevData, [groupID]: response.answers }));
      },
      (error) => {
        console.log(error);
      });
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const restoreJobDescription = (groupID) => {
    const confirmed = window.confirm(t('restorejobdescriptionconfirmation'))
    if (confirmed) {
      PromptService.promptRestore(groupID, assistantID, organizationID).then(
      (response) => { 
        console.log(response);
        navigate("/"+appslug+"/job-description")
        //setPromptList(response.prompts);
      }).catch(
        (error) => {
          console.log(error);
        }
      );
    }
  }
  console.log("promptList", promptList);
  const handlePageClick = (page) => {
    setPageNumber(page);
  };

  const handleNextClick = () => {
    if (pageNumber < totalProgressPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePreviousClick = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleFirstClick = () => {
    setPageNumber(1);
  };

  const handleLastClick = () => {
    setPageNumber(totalProgressPages);
  };
  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 7;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);

    let startPage = Math.max(1, pageNumber - halfMaxPagesToShow);
    let endPage = Math.min(totalProgressPages, pageNumber + halfMaxPagesToShow);

    if (pageNumber - halfMaxPagesToShow < 1) {
      endPage = Math.min(totalProgressPages, endPage + (halfMaxPagesToShow - (pageNumber - 1)));
    }

    if (pageNumber + halfMaxPagesToShow > totalProgressPages) {
      startPage = Math.max(1, startPage - ((pageNumber + halfMaxPagesToShow) - totalProgressPages));
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };
  const groupedArray = [];
  const questionArray = [];
  const groupIndexMap = {};
  console.log("groupData", groupData)
	return (
        <div  >
            <div className="container-fluid">
              <div className="row">
                

                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap d-lg-none d-block align-items-center pt-3 pb-2 mb-3">
                        <button className="navbar-toggler bg-light position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="bi bi-list"></i>
                          </button >
                    </div>
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-1">
                    <div className="col-md-3 col-lg-7 px-4 ">
                      <h1 className="h1">{t('jobhistory')}</h1>
                      <div className="breadcrumb">
                        <Link to={"/"+appslug+"/job-description"} >{t('jobdescriptionforseoassistant')}</Link>&nbsp; &raquo; {t('jobhistory')}
                      </div>
                    </div>
                    <CreditBar creditUsed={creditUsed} totalCredit={totalCredit}  /><TopBar />
                  </div>
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-0 mb-3">
                    <div className="col-md-3 col-lg-8 px-4 ">
                    
                    </div>
                  </div>
                  
                  <div className="container-fluid mt-4">
                    <div className="row">
                      
                         <div className="col-12 fm-content-box p-4">
                          <div className="col-12"><h3>{t('previousjobdescription')}</h3></div>
                            <div className="col-12 table-responsive mt-4 fm-table">
                              <table className="table table-hover align-top">
                               <tbody>
                                  <tr>
                                    <th scope="col">SR.</th>
                                    <th scope="col">{t('jobdescription')}</th>
                                    <th scope="col" className="col-1">{t('action')}</th>
                                  </tr>
                                  {groupsList ? groupsList.map((group, key) => {
                                    let printedQuestions = new Set();
                                    return <>
                                            <tr>
                                              <td>{group.serial_number}</td>
                                              <td>
                                                {groupData[group.group] ? groupData[group.group].map((value, key) => {
                                                  var questionTitle;
                                                  if (!printedQuestions.has(value.question)) {
                                                    questionTitle = <><br /><b>{value.question}</b><br /></>;
                                                    printedQuestions.add(value.question); // Mark question as printed
                                                  }
                                                  return <>
                                                          <b>{questionTitle}</b>{value.answer_heading ? value.answer_heading+' - ' : ''} {value.answer}<br />
                                                        </>
                                                }) : ''}
                                              </td>
                                              <td>
                                              <img alt={group.group} onClick={() => restoreJobDescription(group.group) } title={t('restore')} className="close-btn" src={RestoreIcon} />
                                              </td>
                                            </tr>
                                          </>
                                  }) : ''}

                                  
                                  
                                 
                                  
                                   </tbody>
                                   <tr>
                                      <td colspan="5">
                                        <button className="paging" onClick={handleFirstClick} disabled={pageNumber === 1}>{t('first')}</button>
                                        <button className="paging" onClick={handlePreviousClick} disabled={pageNumber === 1}>{t('previous')}</button>
                                        {generatePageNumbers().map(page => (
                                          <button  className={pageNumber === page ? 'currpaging' : 'paging'}
                                            key={page}
                                            onClick={() => handlePageClick(page)}
                                            
                                          >
                                            {page}
                                          </button>
                                        ))}
                                        <button className="paging" onClick={handleNextClick} disabled={pageNumber === totalProgressPages}>{t('next')}</button>
                                        <button className="paging" onClick={handleLastClick} disabled={pageNumber === totalProgressPages}>{t('last')}</button>
                                    
                                      </td>
                                    </tr>
                                </table>

                            </div>
                            
                            
                            
                         </div>
                     </div>
                     
                     
                  </div>
                  
                  
                </main>
              </div>
            </div>
        	
        </div>
        )
}
export default JobHistory;
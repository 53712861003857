import {useState, useEffect} from 'react';
import {useNavigate, useParams  } from 'react-router-dom';
import "../styles/bootstrap.min.css";
import UserService from "../services/UserService";
import LoginCheck from "./LoginCheck";
import { useTranslation } from 'react-i18next';
import AssistantService from "../services/AssistantService";
import UserAvatar  from "../images/user-avatar.png";
import Notification  from "../images/notifications_none.png";
import Theme  from "../images/moon-solid.png";
import Information  from "../images/info_outline.png";
import Logout  from "../images/logout.png";
import LanguageIcon  from "../images/language-icon.png";

function CreditBar({creditUsed, totalCredit}) {
  const { appslug } = useParams();
  const { i18n } = useTranslation();
  /*const [totalCredit, setTotalCredit] = useState();
  const [creditUsed, setCreditUsed] = useState();*/
  const [organizationID, setOrganizationID] = useState(parseInt(window.sessionStorage.getItem('organization_id'),10));
  
  const { t } = useTranslation();
  const totalPrcent = Math.round(creditUsed*100/totalCredit);
  /*useEffect(() => {
    AssistantService.getAssistantIDBySlug(appslug, organizationID).then(
      (assistresponse) => {
        setCreditUsed(credit)
        setTotalCredit(assistresponse.credits)
        //setCreditUsed(assistresponse.consumed)
      }
    )
  }, [appslug])*/
  //
	return (
        <>
            <div className="col-md-3 col-lg-3 p-4 ms-auto ">
              <div className="creditProgressHeading"><b>{t('credits')}:</b> {totalPrcent}% {t('used')}</div>
              <div className="creditProgressBarContainer">
                <div className={totalPrcent > 90 ? "creditProgressBarMaroon" : totalPrcent > 75 ? "creditProgressBarRed" : totalPrcent > 50 ? "creditProgressBarOrange" : totalPrcent > 25 ? "creditProgressBarYellow" : "creditProgressBarGreen"} style={{width: creditUsed*100/totalCredit+"%" }}>&nbsp;</div>
              
              </div>
              <div className="creditProgressText"><b>{t('Used')}:</b> {creditUsed} {t('outof')} {totalCredit}. <b>{t('remaining')}:</b> {totalCredit-creditUsed}</div>
              
            </div>
            

        </>
        )
}
export default CreditBar;
import { Link  } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import PIMServices from "../services/PIMServices";
import "../styles/bootstrap.min.css";
import 'semantic-ui-css/components/icon.min.css';
import TopBar from "./Topbar";
import SideBar from "./Sidebar";
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react'
function PIMConfig() {
  document.body.style.backgroundColor = '#FFFFFF';
  const { t } = useTranslation();
  const [organizationID, setOrganizationID] = useState(parseInt(window.sessionStorage.getItem('organization_id'),10));
  const [PIMSaveMsg, setPIMSaveMsg] = useState();
  const [dailyWeeklyMonthly, setDailyWeeklyMonthly] = useState();
  const [monthDay, setMonthDay] = useState();
  const [showPIMIntervals, setShowPIMIntervals] = useState(false);
  const [selectedTime, setSelectedTime] = useState();
  const [assistantID, setAssistantID] = useState("");
  const [saveFrequencyMsg, setSaveFrequencyMsg] = useState();
  const [showPIMImportAttr, setShowPIMImportAttr] = useState(false);
  const [attributesList, setAttributesList] = useState();
  const [selectedAttribute, setSelectedAttribute] = useState([]);
  const [selectedAttributeIDs, setSelectedAttributeIDs] = useState([]);
  const [showPIMAttributeSchedule, setShowPIMAttributeSchedule] = useState(false);
  const [showFirstImportButton, setShowFirstImportButton] = useState(false);
  const [firstImportEnable, setFirstImportEnable] = useState("disabled");
  const [productCount, setProductCount] = useState(0);
  const [totalProducts, setTotalProducts] = useState("");
  const [showPIMFirstStep, setShowPIMFirstStep] = useState(true);
  const [showPIMSecondStep, setShowPIMSecondStep] = useState(false);
  const [showPIMThirdStep, setShowPIMThirdStep] = useState(false);
  const [checkStartImportNow, setCheckStartImportNow] = useState(false);
  const [importChanged, setImportChanged] = useState("no")
  const [PIMProgressList, setPIMProgressList] = useState(null)
  const [showManualApprove, setShowManualApprove] = useState(false);
  const [manualApproveOutputID, setManualApproveOutputID] = useState({});
  const [showsaveManualOutputMsg, setShowsaveManualOutputMsg] = useState();
  const [moveToFeed, setMoveToFeed] = useState();
  const [exportFieldMsg, setExportFieldMsg] = useState();
  const [exportSelectedAttributeDescID, setExportSelectedAttributeDescID] = useState();
  const [exportSelectedAttributeMetaID, setExportSelectedAttributeMetaID] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [totalProgressPages, setTotalProgressPages] = useState(1);
 
   //const differenceInHours = useState("");
  // console.log("checkStartImportNow", checkStartImportNow);
  const [selectedDays, setSelectedDays] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });
  const totalPrcent = Math.round(productCount*100/totalProducts);
   const selectWeeklyDays = (day) => {
    setSelectedDays((prevSelectedDays) => ({
      ...prevSelectedDays,
      ...day,
    }));
  };
  //console.log("selectedTime", selectedTime.format('hh:mm:ss'));
  const days = Array.from({ length: 31 }, (_, index) => index + 1);
  const handleMonthDayChange = (event) => {
    setMonthDay(event.target.value);
  }
  const ResumeStopFirstImport = (resumestop) => {
    if(resumestop=="stopped") {
      var confirmed = window.confirm(t('stopproductimportconfirmation'));
    } else {
      var confirmed = true;
    }

    if (confirmed) {
      PIMServices.resumeStopImport(resumestop, organizationID).then(
        (response) => {
          console.log(response)
          setShowFirstImportButton(!showFirstImportButton);
          setFirstImportEnable(resumestop)
        }
      ).catch(
        (error) => {
          console.log("error in stop/resume first import", error)
        }
      )
    }
  }
  const handleSelectChange = (event) => {
    setSelectedDays({
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    })
    setDailyWeeklyMonthly(event.target.value);
  };
  const times = Array.from({ length: 24 }, (_, i) => {
    const hour = i.toString().padStart(2, '0'); // Pad single digits with a leading zero
    return `${hour}:00`;
  });
  const handleTimeChange = (event) => {
    /*if (time) {
        // Set the minutes to 00
        const adjustedTime = time ? time.minutes(0) : null;*/
        setSelectedTime(event.target.value+':00');
    //}
    ///setSelectedTime(value);
  };
 
  const [PIMFields, setPIMFields] = useState({
    pim_url:"",
    pim_username: "",
    pim_password: "",
    pim_structure: "",
    last_cron_date: ""
  });
  

  useEffect(() => {
    PIMServices.PIMAttributes(organizationID).then(
      (response) => {
        //console.log(response.data);
        //setAttributesList(response.data);
        setAttributesList(prevList => {
          // Check if response.data exists and is an array before mapping
          if (Array.isArray(response.data)) {
            const newItems = response.data.map((values) => ({
              value: values.id,
              text: values.name
            }));

            // Merge the previous list with the new items
            return [...(prevList || []), ...newItems]; // Ensure prevList is an array, even if initially undefined
          }
          return prevList || []; // Return the previous list or an empty array if data is invalid
        });
      }
    ).catch(
      (error) => {
        console.log("error fetching attributes", error);
      }
    )

    

    PIMServices.getPIMConfig(organizationID).then(
      (response) => {
        let differenceInHours = "0000";
        let differenceInMinutes = "";
        let formattedDifference = "0000";
        
        //console.log("response.config.last_cron_date", response.config.last_cron_date);
        if(response.config.last_cron_date==null || response.config.last_cron_date=="0000-00-00 00:00:00"){
          //differenceInHours = 0;
          
        } else {
          const utcDateStr = response.config.last_cron_date;
          const utcDateObj = new Date(utcDateStr + " UTC");  // Ensure correct format for Date parsing

          const options = { 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric', 
            hour: 'numeric', 
            minute: 'numeric', 
            hour12: true 
          };

          formattedDifference = utcDateObj.toLocaleString('en-US', options);

          //formattedDifference = response.config.last_cron_date
          /*const parsedLastCronDate = new Date(response.config.last_cron_date);
          const currentDate = new Date();
          const differenceInMillis = currentDate - parsedLastCronDate;
          const differenceInHours = Math.floor(differenceInMillis / (1000 * 60 * 60));
          const differenceInMinutes = Math.floor((differenceInMillis % (1000 * 60 * 60)) / (1000 * 60));

          if (differenceInHours > 0) {
            formattedDifference = `${differenceInHours} hours and ${differenceInMinutes} minutes`;
          } else {
            formattedDifference = `${differenceInMinutes} minutes`;
          }*/
          //console.log("founddddddd");
          //console.log("response.config.last_cron_date", response.config.last_cron_date);
        }
        console.log("differenceInHours", differenceInHours)
        setPIMFields({
          pim_url: response.config.pim_url,
          pim_username: response.config.pim_username,
          pim_password: response.config.pim_password,
          pim_structure: response.config.pim_structure,
          export_field_description: response.config.export_field_description,
          last_cron_date: formattedDifference
        })
        setExportSelectedAttributeDescID(response.config.export_field_description);
        setExportSelectedAttributeMetaID(response.config.export_field_meta);
        setMoveToFeed(response.config.move_to_feed)
        setTotalProducts(response.config.total_products)
        setShowPIMAttributeSchedule(true)
        setShowPIMFirstStep(false)
        setShowPIMSecondStep(true)
      }
    ).catch(
      (error) => {
        console.log("Error fetching PIM config", error)
      }
    )
    PIMServices.GetPIMFrequency(organizationID).then(
      (response) => {
        setShowFirstImportButton(true)
        setShowPIMIntervals(true)
        setDailyWeeklyMonthly(response.pimfrequency.frequency)
        setSelectedTime(response.pimfrequency.time)
        setMonthDay(response.pimfrequency.days)
        setFirstImportEnable(response.pimfrequency.first_import)
        setSelectedDays(JSON.parse(response.pimfrequency.days))
        setProductCount(response.product_count)
        //console.log("aaaaaaaa",response.pimfrequency.attribute_ids.split(',').map(Number));
        setSelectedAttributeIDs(response.pimfrequency.attribute_ids.split(',').map(Number));
        //setSelectedAttribute(response.attributes)
        setShowPIMImportAttr(true);
        setShowPIMSecondStep(true);
        setShowPIMThirdStep(true);
        setCheckStartImportNow(response.pimfrequency.start_import)
        setImportChanged(response.pimfrequency.import_changed)
        
      }
    ).catch(
      (error) => {
        console.log("Error fetching PIM Frequency", error)
      }
    )
  },[PIMSaveMsg])

  useEffect(() => {
    PIMServices.PIMProgressList(organizationID, pageNumber).then(
      (response) => {
        
        setPIMProgressList(response.pimprogresses)
        setTotalProgressPages(response.totalPages)
      }
    ).catch(
      (error) => {
        console.log("error fetching PIMProgress", error);
      }
    )
  }, [pageNumber])
  const setFirstImportReady = (organizationID) => {
    var confirmed = window.confirm(t('startproductimportconfirmation'));
    if(confirmed){
      setFirstImportEnable("started")
      //console.log("setFirstImportReady", organizationID);
      PIMServices.setPIMFirstImportStart(organizationID).then(
        (response) => {
         setShowFirstImportButton(false)
        }
      ).catch(
        (error) => {
          console.log("Error saving PIM config", error.response.data.error)
        }
      )
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    PIMServices.savePIMConfig(organizationID, PIMFields).then(
      (response) => {
        if(response.accessToken.error=="invalid_client"){
          setPIMSaveMsg(t('pimauthfailedmessage'))
        } else {
          setPIMSaveMsg(response.message)
          setShowPIMFirstStep(false)
          setShowPIMSecondStep(true)
          setShowPIMAttributeSchedule(true)
        }
      }
    ).catch(
      (error) => {
        setPIMSaveMsg(t('pimauthfailedmessage'))
        console.log("Error saving PIM config", error.response.data.error)
      }
    )
  }
  const handleChange = (event) => {
    if(event.keyCode=='13'){
      handleSubmit(event);
    }
    setPIMFields({
        ...PIMFields,
        [event.target.name]: event.target.value
    });
  };

  const savePIMFrequency = () => {
    var confirmed = window.confirm(t('saveattributeconfirmation'));
    if(confirmed){
      const params = {
        frequency : dailyWeeklyMonthly,
        days : dailyWeeklyMonthly=='weekly' ? JSON.stringify(selectedDays) : monthDay,
        time : selectedTime,
      }
      //console.log("selectedDays", selectedDays, dailyWeeklyMonthly, monthDay)
      if(selectedAttributeIDs.length === 0){
        setSaveFrequencyMsg(t('attributesseelctionmandatory'));
        return
      } 
      if(!dailyWeeklyMonthly){
        setSaveFrequencyMsg(t('frequencyselectionmandatory'));
        return
      } 
      if(dailyWeeklyMonthly=='weekly' && !Object.values(selectedDays).some(value => value)){
        setSaveFrequencyMsg(t('frequencydaysselectionmandatory'));
        return
      }
      if(dailyWeeklyMonthly=='monthly' && !monthDay){
        setSaveFrequencyMsg(t('frequencydaysselectionmandatory'));
        return
      } 

      PIMServices.SetPIMFrequency(organizationID, dailyWeeklyMonthly, params.days, params.time, selectedAttributeIDs.join(','), checkStartImportNow, importChanged).then(
        (response) => {
          console.log(response);
          setSaveFrequencyMsg(response.message);
          setShowFirstImportButton(true);
          setFirstImportEnable("started");
        }
      ).catch(
        (error) => {
          console.log("Error setting frequency", error);
        }
      )
    }
    //console.log(dailyWeeklyMonthly, selectedDays, monthDay, selectedTime.format('hh:mm:ss'));
  }

  const handleAttributeSelect = (event, data) => {
    setSelectedAttributeIDs(data.value)
      
  };

  const handleExportAttributeSelectDesc = (event, data) => {
    setExportSelectedAttributeDescID(data.value)
      
  };
  const handleExportAttributeSelectMeta = (event, data) => {
    setExportSelectedAttributeMetaID(data.value)
      
  };
  const setAutoApprove = (autoManual) => {
    if(autoManual=="automatic"){
      var confirmed = window.confirm(t('setautoapproveconfirmation'));
      setShowManualApprove(false)
    } else {
      setShowManualApprove(true)
    }
    if(confirmed || autoManual=="manual"){
      setMoveToFeed(autoManual)
      PIMServices.setAutoMunualApprove(organizationID, autoManual).then(
        (response) => {
          //document.getElementById("outputitem_"+outputId).style.display = 'none';
          //console.log(response);
        }
      ).catch(
          (error) => {
              console.error("Error setAutoApprove:", error);
          }
      );
    }
  }

  
  const saveExportToField = () => {
    console.log("exportSelectedAttributeDescID", exportSelectedAttributeDescID);
    PIMServices.saveExportToField(organizationID, exportSelectedAttributeDescID, exportSelectedAttributeMetaID).then(
      (response) => {
        setExportFieldMsg(response.message)
      }
    ).catch(
      (error) => {
        
        console.log("Error saving PIM config", error.response.data.error)
      }
    )
  }


  const handlePageClick = (page) => {
    setPageNumber(page);
  };

  const handleNextClick = () => {
    if (pageNumber < totalProgressPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePreviousClick = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleFirstClick = () => {
    setPageNumber(1);
  };

  const handleLastClick = () => {
    setPageNumber(totalProgressPages);
  };
  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 7;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);

    let startPage = Math.max(1, pageNumber - halfMaxPagesToShow);
    let endPage = Math.min(totalProgressPages, pageNumber + halfMaxPagesToShow);

    if (pageNumber - halfMaxPagesToShow < 1) {
      endPage = Math.min(totalProgressPages, endPage + (halfMaxPagesToShow - (pageNumber - 1)));
    }

    if (pageNumber + halfMaxPagesToShow > totalProgressPages) {
      startPage = Math.max(1, startPage - ((pageNumber + halfMaxPagesToShow) - totalProgressPages));
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };


	return (
        <div  >
            <div className="container-fluid">
              <div className="row">
                

                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center d-lg-none d-block pt-3 pb-2 mb-3">
                    <button className="navbar-toggler bg-light position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                      <i className="bi bi-list"></i>
                    </button>
                  </div>
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3  mb-1">
                    <div className="col-md-3 col-lg-7  col-12 px-4 ">
                      <h1 className="h1">{t('pimconfig')}</h1>
                      <div className="breadcrumb">
                        <Link  to={"/tools"}>{t('tools')}</Link>&nbsp;&raquo;&nbsp;{t('pimconfig')}
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-3 p-4 ">
                    &nbsp;
                    </div>
                    <TopBar />
                  </div>
                  <div className="container-fluid">
                  <hr />
                  </div> 

                  {/* STEP 1*/}
                  <div className="container-fluid mt-4">
                    <div className="row">
                      <div className="col-12 col-lg-8">
                        <h4 className="h4">{t('step1connecttofeed')}</h4>
                      </div>
                    </div>
                    {showPIMFirstStep ? 
                    <form action="post"  onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-12">
                        <h6>{t('pimurl')}</h6>
                      </div>
                      <div className="col-12 col-lg-8">
                        
                        <input
                          type="text"
                          name="pim_url"
                          className="form-control"
                          placeholder=""
                          aria-label=""
                          aria-describedby=""
                          value={PIMFields.pim_url}
                          onChange={handleChange} 
                        />
                      </div>
                      <div className="col-12 col-lg-8  pt-4">
                        <h6>{t('pimusername')}</h6>
                      </div>
                      <div className="col-12 col-lg-8">
                        
                        <input
                         type="text"
                          name="pim_username"
                          className="form-control"
                          placeholder=""
                          aria-label=""
                          aria-describedby=""
                          value={PIMFields.pim_username}
                          onChange={handleChange} 
                        />
                      </div>
                      <div className="col-12 col-lg-8 pt-4">
                        <h6>{t('pimpassword')}</h6>
                      </div>
                      <div className="col-12 col-lg-8">
                        
                        <input
                          type="password"
                          name="pim_password"
                          className="form-control"
                          placeholder=""
                          aria-label=""
                          aria-describedby=""
                          value={PIMFields.pim_password}
                          onChange={handleChange} 
                        />
                      </div>
                      <div className="col-12 col-lg-8  pt-4">
                        <h6>{t('pimstructureid')}</h6>
                      </div>
                      <div className="col-12 col-lg-8">
                        
                        <input
                          type="text"
                          name="pim_structure"
                          className="form-control"
                          placeholder=""
                          aria-label=""
                          aria-describedby=""
                          value={PIMFields.pim_structure}
                          onChange={handleChange} 
                        />
                      </div>
                      <div className="col-12 col-lg-8  pt-4">
                        <button className="btn btn-md fm-btn w-auto float-left me-md-2" type="submit" >{t('saveandauthenticate')}</button>
                        <button className="btn btn-md fm-btn w-auto float-left me-md-2 m-2" type="button" onClick={() => {setShowPIMFirstStep(false); setShowPIMSecondStep(false); setShowPIMThirdStep(true); setShowPIMAttributeSchedule(true); } } >{t('cancel')}</button>
                        {PIMSaveMsg ? PIMSaveMsg : ''}
                      </div>
                    </div>
                    </form>
                    : 
                    showPIMSecondStep || showPIMThirdStep ? 
                    (
                    <>
                    <div className="row">
                      <div className="col-12">
                      {t('feedisconnectedto')}:  <b>{PIMFields.pim_structure}</b>
                      <br />
                      <br />
                      <button  onClick={() => { setShowPIMFirstStep(true); setShowPIMSecondStep(false); setShowPIMThirdStep(false); }} className="btn btn-md fm-btn me-md-2">{t('change')}</button>
                      </div>
                    </div>
                    
                    </>)
                    : ""
                    }
                  </div>
                  
                  {/* STEP 1*/}

                  <div className="container-fluid mt-4">
                    <div className="row">
                      <div className="col-12">
                      <hr />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-12 navigateButton">
                        <div className="" align="right">
                          <ul class="nav nav-tabs mb-3" id="pills-tab" role="tablist">

                            <li class="nav-item" role="presentation">
                              <button class="nav-link  active" id="pills-completed-tab" data-bs-toggle="pill" data-bs-target="#pills-completed" type="button" role="tab" aria-controls="pills-completed" aria-selected="false">{t('import')}</button>
                            </li>
                            <li class="nav-item" role="presentation">
                              <button class="nav-link  " id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">{t('export')}</button>
                            </li>
                           
                          </ul>
                             
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 pt-4">
                        <div className="tab-content" id="pills-tabContent">
                          <div className="tab-pane fade show active" id="pills-completed" role="tabpanel" aria-labelledby="pills-completed-tab">
                              {/* STEP 2*/}
                              <div className="mt-4">
                                {showPIMSecondStep && showPIMThirdStep==false ? 
                                (
                                <>
                                
                                <div className="row mt-4">
                                  <div className="col-12 col-lg-8">
                                    <h4 className="h4">{t('step2chooseattributes')}</h4>
                                  </div>
                                </div>
                                
                                <div className="row">
                                  <div className="col-12 col-lg-8">
                                    <p>{t('automaticpimimportinstruction')}</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-2">
                                    
                                  </div>
                                  
                                </div>
                                
                                <div className="row">

                                  <div className="col-12 col-lg-4">
                                    <div className="pt-3">
                                      
                                      {t('lastpimimport')}: {PIMFields.last_cron_date=="0000" ? "Never" : PIMFields.last_cron_date }
                                      
                                    </div>
                                  </div>
                                  <div className="col-1 pt-2">
                                    {firstImportEnable =="started" ? 
                                    (
                                      <>
                                        <button className="btn btn-md fm-btn me-md-2" onClick={() => ResumeStopFirstImport('stopped') } type="submit" >
                                        {t('stop')}
                                        </button>
                                      </>
                                    ) : 
                                      firstImportEnable =="stopped" ? 
                                      (
                                      <>
                                        <button className="btn btn-md fm-btn me-md-2" onClick={() => ResumeStopFirstImport('started') } type="submit" >
                                        {t('resume')}
                                        </button>
                                      </>
                                      ) : " "
                                    }
                                  </div>
                                  <div className="col-3">
                                    {/*
                                    <div className="pt-3">
                                      <div className="creditProgressBarContainer">
                                        <div className="creditProgressBarGreen" style={{width: totalPrcent+"%" }}>&nbsp;</div>
                                      </div>
                                      <div className="creditProgressText"><b>{t('imported')}:</b> {productCount} {t('outof')} {totalProducts}. <b>{t('remaining')}:</b> {totalProducts-productCount}</div> 
                                       
                                    </div>
                                    */}
                                  </div>
                                </div>
                              
                                <div className="row">
                                  <div className="mt-4">
                                  <h6 className="h5">{t('selectattributesforimport')}</h6>
                                    {/*}<button className="btn btn-lg fm-btn me-md-2" type="button" onClick={() =>  setShowPIMImportAttr(!showPIMImportAttr) } >{t('selectattributesforimport')}</button> {*/}
                                  </div>
                                </div>
                                
                                <div className="row pt-4">
                                  <div className="col-12 col-lg-8">
                                      <Dropdown value={selectedAttributeIDs}  fluid search multiple clearable selection   onChange={(event, data) => handleAttributeSelect(event, data)}  options={attributesList} />
                                       
                                  </div>
                                </div>
                                <div className="row pt-4">
                                  <div className="col-12 col-lg-8">
                                      <button className="btn btn-md m-2 fm-btn me-md-2" onClick={() => { setShowPIMThirdStep(true); setShowPIMSecondStep(false); }}>{t('continuetoimport')}</button> 
                                      <button className="btn btn-md m-2 fm-btn me-md-2" type="button" onClick={() => {setShowPIMFirstStep(false); setShowPIMSecondStep(false); setShowPIMThirdStep(true); setShowPIMAttributeSchedule(true); } } >{t('cancel')}</button>
                                  </div>
                                </div>
                                <div className="row pt-2">
                                  <div className="col-12">
                                    <div className="row">

                                      {selectedAttribute.map((value, index) => (
                                          <div className="attrib">
                                          {value.name}
                                          </div>
                                      ))}
                                     
                                    </div>
                                  </div>
                                </div>
                                </>
                                )
                                : 

                                showPIMThirdStep ? 
                                (
                                <>
                                  
                                  <div className="row mt-4">
                                    <div className="col-12 col-lg-8">
                                      <h4 className="h4">{t('step2chooseattributes')}</h4>
                                    </div>
                                  </div>
                                  <div className="row mt-2">
                                    <div className="col-12">
                                    <button onClick={() => { setShowPIMSecondStep(true); setShowPIMThirdStep(false); }} className="btn btn-md fm-btn me-md-2">{t('change')}</button>
                                    </div>
                                  </div>
                                </>
                                ) : "" }
                              </div>
                              {/* STEP 2*/}

                              {/* STEP 3*/}
                              <div className=" mt-4">
                                {showPIMThirdStep ?
                                <>
                                <div className="row">
                                  <div className="col-12">
                                  <hr />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className=" col-12 col-lg-8 mt-4">
                                    <h4 className="h4">{t('step3import')}</h4>
                                    <p>{t('selectintervalforimimportsubline')}</p>
                                    {/*}<button className="btn btn-lg fm-btn me-md-2" type="button" onClick={() => setShowPIMIntervals(!showPIMIntervals)} >{t('selectintervalforimimport')}</button>{*/}
                                  </div>
                                </div>
                               
                                <div className="row pt-4">
                                  <div className="col-12 col-lg-8">
                                  <input type="checkbox" value="yes" checked={checkStartImportNow==true ? "checked" : ""} name="startimportnow" onChange={() => { setCheckStartImportNow(!checkStartImportNow) } } className="checkbox" /> {t('startimportnow')}
                                  </div>
                                </div>
                                
                                <div className="row pt-4">
                                  <div className="col-12 col-lg-8">
                                  <input type="checkbox" value="yes" checked={importChanged=="yes" ? "checked" : ""}  onChange={(e) => {setImportChanged(e.target.checked ? "yes" : "no")}} name="importtime" className="checkbox" /> {t('continouschangedimport')}
                                  </div>
                                </div>
                                {importChanged=="yes" ?
                                <div className="row pt-4">
                                  <div className="col-12  m-lg-3 col-lg-2">
                                    <br />
                                    <select className="form-control" onChange={handleSelectChange}>
                                      <option value="">{t('select')}</option>
                                      <option value="hourly" selected={dailyWeeklyMonthly=="hourly" ? "selected" : ""}>{t('hourly')}</option>
                                      <option value="daily" selected={dailyWeeklyMonthly=="daily" ? "selected" : ""}>{t('daily')}</option>
                                      <option value="weekly" selected={dailyWeeklyMonthly=="weekly" ? "selected" : ""}>{t('weekly')}</option>
                                      <option value="monthly" selected={dailyWeeklyMonthly=="monthly" ? "selected" : ""}>{t('monthly')}</option>
                                    </select>
                                  </div>
                                  <div className="col-12 m-lg-3 mt-0 col-lg-5">
                                    {dailyWeeklyMonthly=="daily" ?
                                    (
                                    <div className="row">
                                      <div className="col-12 col-lg-4">
                                      {t('timeofday')}
                                      <select className="form-control" onChange={handleTimeChange} >
                                        {times.map((time, index) => (
                                          <option selected={selectedTime==time+':00' ? "selected" : ""} key={index} value={time}>
                                            {time}
                                          </option>
                                        ))}
                                      </select>
                                      </div>
                                     </div>)
                                    : dailyWeeklyMonthly=="weekly" ?
                                      (
                                      <div className="row">
                                        <div className="float-left w-auto px-3">
                                          <div className="row">
                                            {t('weekday')}
                                          </div>
                                          <div className="row">
                                            <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.monday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({monday: !selectedDays.monday})}>{t('monday')}</div>
                                            <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.tuesday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({tuesday: !selectedDays.tuesday})}>{t('tuesday')}</div>
                                            <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.wednesday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({wednesday: !selectedDays.wednesday})}>{t('wednesday')}</div>
                                            <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.thursday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({thursday: !selectedDays.thursday})}>{t('thursday')}</div>
                                            <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.friday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({friday: !selectedDays.friday})}>{t('friday')}</div>
                                            <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.saturday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({saturday: !selectedDays.saturday})}>{t('saturday')}</div>
                                            <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.sunday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({sunday: !selectedDays.sunday})}>{t('sunday')}</div>
                                          
                                          </div>
                                          
                                        </div>
                                        <div className="float-left w-25">
                                          <div className="row">
                                            {t('timeofday')}
                                          </div>
                                          <div className="row">
                                            <div className="col-12 p-0">
                                              
                                              <select className="form-control" onChange={handleTimeChange} >
                                                {times.map((time, index) => (
                                                  <option selected={selectedTime==time+':00' ? "selected" : ""} key={index} value={time}>
                                                    {time}
                                                  </option>
                                                ))}
                                              </select>
                                            </div>
                                          </div>

                                        </div>
                                                                       
                                        
                                        </div>)
                                    : dailyWeeklyMonthly=="monthly" ?
                                      (<div className="row">
                                        <div  className="col-6 col-lg-3">
                                        {t('date')}
                                        <select className="form-control" onChange={handleMonthDayChange}>
                                          <option value="">{t('select')}</option>
                                          {days.map((day) => (
                                            <option key={day} value={day} selected={monthDay==day ? "selected" : ""}>
                                              {day}
                                            </option>
                                          ))}
                                        </select>
                                        </div>
                                        <div className="col-6 col-lg-3">
                                          {t('timeofday')}
                                          <select className="form-control" onChange={handleTimeChange} >
                                            {times.map((time, index) => (
                                              <option selected={selectedTime==time+':00' ? "selected" : ""} key={index} value={time}>
                                                {time}
                                              </option>
                                            ))}
                                          </select>
                                          
                                        </div>
                                      </div>)
                                    : ''
                                    }
                                  </div>
                                  
                                </div>
                                : '' }
                                <div className="row pt-4">
                                  <div className="col-12">
                                    <button disabled={firstImportEnable == "started" ? "disabled" : ""} className="btn btn-md fm-btn me-md-2" type="button" onClick={() => savePIMFrequency()} >{t('save')}</button>
                                    {saveFrequencyMsg ? saveFrequencyMsg : ''}
                                  </div>
                                </div>
                                <div className="row pt-4">
                                  <div className="col-12">
                                    {/* showFirstImportButton && firstImportEnable =="disabled"? 
                                      (
                                        <>
                                    <button className="btn btn-md fm-btn me-md-2" type="button" onClick={() => setFirstImportReady(organizationID) } >{t('letsstartimporttingproducts')}</button>
                                    </>
                                    ) : "" */}
                                  </div>
                                </div>
                                
                                </>
                                : "" }
                                <div className="row">
                                  <div className=" col-8 ">
                                    <h4 className="h4">{t('importshistory')}</h4>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-lg-8 fm-content-box p-4">
                                    <div className="col-12 table-responsive fm-table">
                                      <table className="table table-hover align-top">
                                      <tbody>
                                      <tr>
                                        <th scope="col">{t('progress')}</th>
                                        <th scope="col">{t('lastrundate')}</th>
                                        <th scope="col">{t('nextrundate')}</th>
                                      </tr>
                                        {PIMProgressList ? 
                                          PIMProgressList.map((val, key) =>{
                                          return (
                                          <tr>
                                            <td>
                                              <div className="pt-3">
                                                <div className="creditProgressBarContainer">
                                                  <div className="creditProgressBarGreen" style={{width: Math.round(val.imported*100/val.total_products)+"%" }}>&nbsp;</div>
                                                  </div>
                                                  <div className="creditProgressText"><b>{t('imported')}:</b> {val.imported} {t('outof')} {val.total_products}. <b>{t('remaining')}:</b> {val.total_products-val.imported}</div> 
                                              </div>
                                            </td>
                                            <td>{val.cron_start_date}</td>
                                            <td>{val.next_cron_date}</td>
                                          </tr>
                                          )
                                          })
                                        : ''}
                                      </tbody>
                                        <tr>
                                          <td colspan="5">
                                            <button className="paging" onClick={handleFirstClick} disabled={pageNumber === 1}>{t('first')}</button>
                                            <button className="paging" onClick={handlePreviousClick} disabled={pageNumber === 1}>{t('previous')}</button>
                                            {generatePageNumbers().map(page => (
                                              <button  className={pageNumber === page ? 'currpaging' : 'paging'}
                                                key={page}
                                                onClick={() => handlePageClick(page)}
                                                
                                              >
                                                {page}
                                              </button>
                                            ))}
                                            <button className="paging" onClick={handleNextClick} disabled={pageNumber === totalProgressPages}>{t('next')}</button>
                                            <button className="paging" onClick={handleLastClick} disabled={pageNumber === totalProgressPages}>{t('last')}</button>
                                        
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <div><br /></div>  
                              </div>
                              {/* STEP 3*/}
                          </div>
                          <div class="tab-pane fade " id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <div className="row m-0 pb-2">
                              <div className="col-12 col-lg-2  pt-2">
                                <div className="row">
                                  <div className="float-left w-auto pl-0 ">
                                    <input onClick={() => setAutoApprove('manual') } checked={moveToFeed=="manual" ? "checked" : ""}  name="feed" className="checkbox" type="radio" />
                                  </div>
                                  <div className="float-left w-auto px-0">
                                    <div className="h6">{t('manuallyapproved')}</div>
                                  </div>
                                </div>
                                
                              </div>
                              <div className="col-12 col-lg-2 pt-2">
                                <div className="row">
                                  <div className="float-left w-auto pl-0 ">
                                    <input onClick={() => setAutoApprove('automatic') } checked={moveToFeed=="automatic" ? "checked" : ""}  name="feed" className="checkbox" type="radio" />
                                  </div>
                                  <div className="float-left w-auto px-0">
                                    <div className="h6">{t('automaticapproved')}</div>
                                  </div>
                                </div>

                              </div>
                              
                            </div>
                            <div className="row m-0 pb-2 pt-3">
                              <div className="col-12">
                                <h6>{t('exporttoattributeseodescriptionfield')}</h6>
                              </div>
                              <div className="col-12 col-lg-4">
                                <Dropdown value={exportSelectedAttributeDescID}  fluid search clearable selection  placeholder={t('select')} onChange={(event, data) => handleExportAttributeSelectDesc(event, data)}  options={attributesList} />
                              </div>

                            </div>
                            <div className="row m-0 pb-2 pt-3">
                              <div className="col-12">
                                <h6>{t('exporttoattributeseometafield')}</h6>
                              </div>
                              <div className="col-12 col-lg-4">
                                <Dropdown value={exportSelectedAttributeMetaID}  fluid search clearable selection placeholder={t('select')}  onChange={(event, data) => handleExportAttributeSelectMeta(event, data)}  options={attributesList} />
                              </div>

                            </div>
                            <div className="row  m-0 pb-2">
                              <div className="col-2  pt-2">
                                <button onClick={() => saveExportToField() } className="btn btn-md fm-btn me-md-2">{t('save')}</button> 
                                {exportFieldMsg ? exportFieldMsg : '' }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </main>
              </div>
            </div>
        	
        </div>
        )
}
export default PIMConfig;
import {NavLink, useNavigate, useParams  } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProductService from "../services/ProductService";
import PIMServices from "../services/PIMServices";
import AssistantService from "../services/AssistantService";
import CreditBar from "./CreditBar";
import AttributeNamesss from "./AttributeNames";

import TopBar from "./Topbar";
import SideBar from "./Sidebar";
import OurValues  from "../images/our-values.png";
import ToneOfVoice  from "../images/tone-voice.png";
import Language  from "../images/language.png";
import JobResult  from "../images/job-result.png";
import Feedback  from "../images/feedback.png";
import BarChart from './BarChart';

function AssistantOverview() {
  const { appslug } = useParams();
  const [totalProducts, setTotalProducts] = useState();
  const [totalOptimized, setTotalOptimized] = useState();
  const [totalReadyForExport, setTotalReadyForExport] = useState();
  const [totalExported, setTotalExported] = useState();
  const [workPlanList, setWorkPlanList] = useState([]);
  const [totalProductsInCriteria, setTotalProductsInCriteria] = useState(0)
  const [statsSEOGenerated, setStatsSEOGenerated] = useState("");
  const [statsSEOTotal, setStatsSEOTotal] = useState("");
  const [showStartAgainModal, setShowStartAgainModal] = useState(false);
  const [startAgainID, setStartAgainID] = useState(false);
  const [organizationID, setOrganizationID] = useState(parseInt(window.sessionStorage.getItem('organization_id'), 10));
  const [totalCredit, setTotalCredit] = useState();
  const [creditUsed, setCreditUsed] = useState();
  const [showdisableWorkScheduleMsg, setShowdisableWorkScheduleMsg] =  useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    AssistantService.getAssistantIDBySlug(appslug, organizationID).then(
      (assistresponse) => {
          ProductService.ProductStats(assistresponse.assistantID, organizationID).then(
           (response) => {
              console.log(response);
              setTotalProducts(response.data.total_products)
              setTotalOptimized(response.data.total_optimized)
              setTotalReadyForExport(response.data.total_ready_for_export)
              setTotalExported(response.data.total_exported)
            }

          ).catch(
            (error) => {
              console.log("Error fetching stats", error);
            }
          )  

          AssistantService.GetWorkPlanList(assistresponse.assistantID, organizationID).then(
            (response) => {
              setWorkPlanList(response.data.workplans)
              //console.log(response);
            }
          ).catch(
            (error) => {
              console.error("Error fetching GetWorkPlanList:", error);  
            }
          )
      }
    )
    
    AssistantService.getAssistantIDBySlug(appslug, organizationID).then(
      (assistresponse) => {
        setTotalCredit(assistresponse.credits)
        setCreditUsed(assistresponse.consumed)
      }
    )
    
    PIMServices.getPIMConfig(organizationID).then(
      (response) => {
        console.log("getPIMConfig success", response)
        setShowdisableWorkScheduleMsg(false)
      }
    ).catch(
      (error) => {
        setShowdisableWorkScheduleMsg(true)
        console.log("getPIMConfig error ", error)
      }
    )  
    
  }, [appslug])

  const startAgainWorkPlan = (workPlanID) => {
    AssistantService.startAgainWorkPlanStats(workPlanID).then(
      (response) => {
        console.log(response.data)
        setTotalProductsInCriteria(response.data.total-response.data.generated)
        setStatsSEOGenerated(response.data.generated)
        setStatsSEOTotal(response.data.total)
        setShowStartAgainModal(true); 
        setStartAgainID(workPlanID) 
      }
    ).catch(
      (error) => {
        console.log("error fetching startAgainWorkPlanStats", error)
      }
    )
    
  }
  const StopResumeWorkPlan = (workPlanID, stopresume) => {
    if(stopresume=="stopped"){
      var confirmed = window.confirm(t('stopworkplanconfirmation'));
    } else if(stopresume=="processing"){ 
      var confirmed = window.confirm(t('resumeworkplanconfirmation'));
    } else if(stopresume=="pending"){ 
      var confirmed = window.confirm(t('restartworkplanconfirmation'));
    }
    if (confirmed) {
      const updatedWorkPlanList = workPlanList.map(workPlan =>
        workPlan.id === workPlanID ? { ...workPlan, status: stopresume } : workPlan
      );
      setWorkPlanList(updatedWorkPlanList);
      AssistantService.StopResumeWorkPlan(workPlanID, stopresume).then(
        (response) => {
          console.log(response)
        }
      ).catch(
        (error) => {
          console.log("error setting StopResumeWorkPlan");
        }
      )
    }
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Format options
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false // Disable 12-hour clock if you want 24-hour format
    };

    return new Intl.DateTimeFormat('en-GB', options).format(date).replace(',', ' at');
  };

	return (
        <div  >
            <div className="container-fluid">
              <div className="row">
                

                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap d-lg-none d-block align-items-center pt-3 pb-2 mb-3">
                      <button className="navbar-toggler bg-light position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="bi bi-list"></i>
                          </button>
                    </div>

                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-1">
                    <div className="col-md-3 col-lg-7 px-4 ">
                      <h1 className="h1">{t('assistantoverview')}</h1>
                      <div className="breadcrumb">
                        {t('assistantoverview')}
                      </div>
                    </div>
                    <CreditBar creditUsed={creditUsed} totalCredit={totalCredit} />
                    <TopBar />
                  </div>
                  
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-0 mb-3">
                    <div className="col-md-3 col-lg-12 px-4 ">
                    <p>{t('assistantoverviewpagesubline')}</p>
                    </div>
                  </div>
                  
                  <div className="container-fluid" >
                    <div className="row"> 
                      {/*
                      <div className=" col-xs-12 col-sm-12 col-md-6 col-lg-4 col-lg-offset-1 p-3" align="center">
                        <div className="fm-top-box-chart ">
                          <div className="assistantOverviewText">
                          <BarChart />
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-lg-offset-1  p-3" align="center">
                        <div className="fm-top-box-chart">
                          <div className="assistantOverviewText">
                          <BarChart />
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-lg-offset-1 p-3" align="center">
                        <div className="fm-top-box ">
                          <div className="assistantOverviewText">
                          <h1>4125</h1>
                          products are not ready for SEO</div>
                          <br />
                          <br />
                          <NavLink to={"/"+appslug+"/completed-task"}><button className="btn btn-lg fm-btn me-md-2" type="button" >{t('viewproducts')}</button> </NavLink>
                        </div>
                      </div>
                       */}
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-lg-offset-1 p-3" align="center">
                        <div className="fm-top-box ">
                          <div className="assistantOverviewText">
                          <h1>{totalProducts}</h1>
                          {t('seoreadyroduct')}</div>
                          <br />
                          <br />
                          <br />
                          <br />
                        </div>
                      </div>
                       
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-lg-offset-1 p-3" align="center">
                        <div className="fm-top-box ">
                          <div className="assistantOverviewText">
                          <h1>{totalOptimized}</h1>
                          {t('optmizedproducts')}</div>
                          <br />
                          <br />
                          <NavLink to={"/"+appslug+"/tasks"}><button className="btn btn-lg fm-btn me-md-2" type="button" >{t('seeseooptimizedproducts')}</button> </NavLink>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-lg-offset-1 p-3" align="center">
                          <div className="fm-top-box ">
                            <div className="assistantOverviewText">
                            <h1>{totalProducts-totalOptimized}</h1>
                            {t('seonotreadyroduct')}</div>
                            <br />
                            <br />
                            <NavLink to={"/"+appslug+"/tasks"}><button className="btn btn-lg fm-btn me-md-2" type="button" >{t('seeunoptimizedproducts')}</button> </NavLink>
                          </div>
                        </div>
                        {totalReadyForExport > 0 ? (<>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-lg-offset-1 p-3" align="center">
                          <div className="fm-top-box ">
                            <div className="assistantOverviewText">
                            <h1>{totalReadyForExport}</h1>
                            {t('readyforexport')}</div>
                            <br />
                            <br />
                            <NavLink to={"/"+appslug+"/tasks"}><button className="btn btn-lg fm-btn me-md-2" type="button" >{t('readyforexport')}</button> </NavLink>
                          </div>
                        </div>
                        </>) : ""}
                        {totalExported > 0 ? (<>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-lg-offset-1 p-3" align="center">
                          <div className="fm-top-box ">
                            <div className="assistantOverviewText">
                            <h1>{totalExported}</h1>
                            {t('exported')}</div>
                            <br />
                            <br />
                            <NavLink to={"/"+appslug+"/tasks"}><button className="btn btn-lg fm-btn me-md-2" type="button" >{t('exported')}</button> </NavLink>
                          </div>
                        </div>
                        </>) : ""}
                      </div>
                      


                      
                  </div>
                  {showdisableWorkScheduleMsg==true ? (
                    <>
                    </>
                  ) : (
                  <>
                  <div className="container-fluid  p-3">
                    <div className="row mt-4  p-2 ">
                      <div className="col-6 col-lg-2 pt-2">
                      <h4 className="h4">{t('activeworkplans')}</h4> 
                      </div>
                      <div className="col-6 col-lg-3">
                        <NavLink to={"/"+appslug+"/work-schedule"}><button className="btn btn-lg fm-btn me-md-2" type="button" >{t('workschedule')}</button> </NavLink>
                          
                      </div>
                    </div>
                    <div className="row   p-3">
                      {workPlanList ?
                      (
                      <div className="col-12 fm-content-box">
                      <div className="col-12 table-responsive mt-4   fm-table ">
                          
                          <table className="table table-hover align-top">
                               <tbody>
                                  <tr>
                                <th scope="col">{t('selectioncriteria')}</th>
                                <th scope="col"> {t('time')}</th>
                                <th scope="col">{t('status')}</th>
                                <th scope="col">{t('activity')}</th>
                              </tr>
                                
                                {workPlanList.map((value, key) => {
                                  return <>
                                    <tr className={"workplan-"+value.status} title={value.status}>
                                      <td width="30%">
                                        {value.type=="criteria" ? <AttributeNamesss idlist={value.attribute_ids} /> :  value.isbns.substr(0,80)+'...'}
                                      </td>
                                      <td  width="25%">
                                        {value.status=="completed" ? t('completed') : (<> {t('nextrun')}: {formatDate(value.cron_start_date)} </> ) }
                                      </td>
                                      <td  width="30%">
                                        <div className="creditProgressBarContainer">
                                          <div className="creditProgressBarGreen" style={{width: Math.round(((100*value.generated_products)/value.total_products))+"%" }}>&nbsp;</div>
                                        </div>
                                        <div className="creditProgressText"><b>{t('updated')}:</b> {value.generated_products} {t('outof')} {value.total_products}. <b>{t('remaining')}:</b> {value.total_products-value.generated_products}</div>
                                      </td>
                                      <td width="15%">
                                        
                                        {value.status=="processing" || value.status=="pending" ? 

                                          (
                                          <>
                                          <button onClick={() => StopResumeWorkPlan(value.id, 'stopped') } className="btn btn-md fm-btn me-md-2">{t('stop')}</button>
                                          </>
                                          )
                                        : 
                                        
                                          value.status=="stopped" ? 
                                         (
                                          <>
                                          <button onClick={() => StopResumeWorkPlan(value.id, 'processing') } className="btn btn-md fm-btn me-md-2">{t('resume')}</button>
                                          </>
                                          )
                                          :
                                          (
                                            <>Not active workplan</>
                                          )
                                        }
                                      </td>
                                      {/* */}
                                    </tr>
                                  </>
                                })}
                               
                              
                              
                            </tbody>
                          </table>
                        </div> 
                        </div> 
                        )
                        : (
                          <div className="col-10 table-responsive mt-4">
                          {t('youhavenoworkplans')}
                          </div>
                        )}
                    </div>
                  </div>
                  </>)}
                </main>
              </div>
            </div>
        	
        </div>
        )
}
export default AssistantOverview;
import axios from "axios";

class UserService {
    
    getUserFromToken(access_token) {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"email": access_token},
            url: process.env.REACT_APP_API_SERVER_URL + "getuser",
        } 
        return axios(options)
            .then(response => {
                return response.data
            })
    }

    saveOutputHistory(isbn, output, assistantID, organizationID, answerData, questionsList){
        const answerArray = Object.entries(answerData).map(([key, value]) => ({
          questionId: Number(key), // Convert key back to a number if necessary
          answers: value,          // Retain the array of answers
        }));
        const data = {
          "isbn": isbn,
          "output": output,
          "assistant_id": assistantID,
          "organization_id": organizationID,
          "answerdata": JSON.stringify(answerArray),
          "questionlist": JSON.stringify(questionsList)
        }
        
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
            url: process.env.REACT_APP_API_SERVER_URL + "saveoutputhistory",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }

    Execution(orgID, userID, assistantID, isbn, answerData, questionList) {
        const answerArray = Object.entries(answerData).map(([key, value]) => ({
          questionId: Number(key), // Convert key back to a number if necessary
          answers: value,          // Retain the array of answers
        }));
        const data = {
            isbn: isbn,
            "assistant_id": assistantID,
            "organization_id": orgID,
            "user_id": userID,
            "answerdata": JSON.stringify(answerArray),
            "questionlist": JSON.stringify(questionList)
        }
        
        const options = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            data: data,
            url: process.env.REACT_APP_API_SERVER_URL + "execute",
        } 
        return axios(options)
        .then(response => {
           return response;
        })

    }

    OrganizationDetails(orgID) {
        const data = {
            organization_id: orgID
        }
        
        const options = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            data: data,
            url: process.env.REACT_APP_API_SERVER_URL + "organizationdetails",
        } 
        return axios(options)
        .then(response => {
           return response;
        })

    }

    GetOrganizationList() {
        
        const options = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            url: process.env.REACT_APP_API_SERVER_URL + "organizationlist",
        } 
        return axios(options)
        .then(response => {
           return response;
        })

    }

    

      
    

}

export default new UserService();
import {useNavigate, useParams, Link  } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import AuthService from "../services/AuthService";
import UserService from "../services/UserService";
import ProductService from "../services/ProductService";
import AssistantService from "../services/AssistantService";
import PromptService from "../services/PromptService";
import ImproveJobQuestion from "./ImproveJobQuestion";
import ImproveJobOneQuestion from "./ImproveJobOneQuestion";
import "../styles/bootstrap.min.css";
import CreditBar from "./CreditBar";
import TopBar from "./Topbar";
import SideBar from "./Sidebar";
import UserAvatar  from "../images/user-avatar.png";
import OurValues  from "../images/our-values.png";
import ToneOfVoice  from "../images/tone-voice.png";
import Language  from "../images/language.png";
import JobResult  from "../images/job-result.png";
import Feedback  from "../images/feedback.png";
import Notification  from "../images/notifications_none.png";
import Theme  from "../images/moon-solid.png";
import CloseIcon  from "../images/close.png";
import AsyncSelect from 'react-select/async';

import Information  from "../images/info_outline.png";
import { useTranslation } from 'react-i18next';
import CloseCircle  from "../images/cross-circle.png";
import { Dropdown } from 'semantic-ui-react'

function ImproveJob() {
  const { appslug } = useParams();
  document.body.style.backgroundColor = '#FFFFFF';
  const { t } = useTranslation();
  const [assistantID, setAssistantID] = useState(false);
  const [userID, setUserID] = useState(parseInt(window.sessionStorage.getItem('user_id'),10));
  const [organizationID, setOrganizationID] = useState(parseInt(window.sessionStorage.getItem('organization_id'),10));
  const [showSaveMsgButton, setShowSaveMsgButton] = useState(false);
  const [outputSavedMsg, setOutputSavedMsg] = useState(null);
  const [savePromptMsg, setSavePromptMsg] = useState("");
  const [executeMessage, setExecuteMessage] = useState(null);
  const [output, setOutput] = useState(null);
  const [promptMessage, setPromptMessage] = useState(null);
  const [prompt, setPrompt] = useState(null);
  const [isbn, setISBN] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [userPromptMessage, setUserPromptMessage] = useState("");
  const [systemdefinedString, setSystemdefinedString] = useState("");
  const [productDetails, setProductDetails] = useState("");
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpModalCotent, setHelpModalCotent] = useState("");
  const [productList, setProductList] = useState([]);
  const [showTestPopUpButton, setShowTestPopUpButton] = useState(false);
  const [totalCredit, setTotalCredit] = useState();
  const [creditUsed, setCreditUsed] = useState();

  const [questionsList, setQuestionsList] = useState();
  const [answerData, setAnswerData] = useState([]);


  const SystemPrompt = ({ text }) => (
    <div dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, '<br />') }} />
  );


  /*const handleISBNSelect = (event, data) => {
    setISBN(data.value)
      
  };*/


  const handleChange = (selectedOption) => {
    setISBN(selectedOption.value);
  };

  //console.log("window.location.origin", window.location.origin);
  useEffect(() => {
    AssistantService.getAssistantIDBySlug(appslug, organizationID).then(
      (assistresponse) => {
        setAssistantID(assistresponse.assistantID);

        PromptService.getAssistantQuestions(assistresponse.assistantID).then(
          (response) => {
            //console.log("response", response);
            setQuestionsList(response.questions)
            response.questions.forEach(question => {
              console.log("question", question.id)
              fetchAnswers(question.id)
            });
          }
        ). catch(
          (error) => {
            console.log("Error fetching getAssistantQuestions", error);
          }
        )

        
     
        
        
      }
    )

    AssistantService.getAssistantIDBySlug(appslug, organizationID).then(
      (assistresponse) => {
        setTotalCredit(assistresponse.credits)
        setCreditUsed(assistresponse.consumed)
      }
    )
  },[appslug])


  const fetchAnswers = async (questionID) => {
    try {
      PromptService.getAnswersByQuestion(questionID, organizationID).then(
      (response) => {
        //console.log(response.data);
        setAnswerData(prevData => ({ ...prevData, [questionID]: response.answers }));
        setShowTestPopUpButton(true)
      },
      (error) => {
        console.log(error);
        setShowTestPopUpButton(false)
      });
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  
  // Function to add a new row to a specific table
  const addRow = (questionID) => {
    setAnswerData((prevData) => {
    // Get the current answers array for the specific questionID
    const currentAnswers = prevData[questionID] || [];
    
    // Append a blank object to the array
    const updatedAnswers = [...currentAnswers, { id:'', answer_heading: '', answer: '' }];
    
    // Return the updated state
    return {
      ...prevData,
      [questionID]: updatedAnswers,
    };
  });
  };

  // Function to remove a row from a specific table
  const removeRow = (questionID, rowIndex) => {
    setAnswerData((prevData) => {
      const currentAnswers = prevData[questionID] || [];
      const updatedAnswers = currentAnswers.filter((_, index) => index !== rowIndex);
      return {
        ...prevData,
        [questionID]: updatedAnswers,
      };
    });
  };

  const saveOutputHistory = () => {
    //console.log(executeMessage);
    UserService.saveOutputHistory(isbn, output, assistantID, organizationID, answerData, questionsList).then(
    (response) => {
      setOutputSavedMsg(t('outputsaved'));
      console.log(response);
    },
    (error) => {
      console.log(error);
    });
  }

  const saveJobDescription = () => {

    console.log(answerData, questionsList);
    PromptService.updateAnswers(assistantID, organizationID, answerData, questionsList).then(
      (response) => {
        setSavePromptMsg(t('informationupdated'));
      }
    ).catch(
      (error) => {

      }
    )
    
  }
  

  const openTestPopUp = () => {
    handleOpenModal();
  }
  const handleClose = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };


  const handleGenerateSEOSubmit = (event) => {
    event.preventDefault();
    setShowSaveMsgButton(false);
    setExecuteMessage(t('executioninprogress')+'...');
    setPromptMessage("");
    setUserPromptMessage("");

    UserService.Execution(organizationID, userID, assistantID, isbn, answerData, questionsList).then(
      (response) => {
        //console.log(response);
        const dataa = JSON.parse(response.data.response);
        console.log(dataa.choices[0].message.content);
        setExecuteMessage(nl2br(dataa.choices[0].message.content));
        setPromptMessage(response.data.questiononeString+response.data.userdefinedString);
        setUserPromptMessage(response.data.userPrompt);
        setProductDetails(response.data.productDetails);
        setSystemdefinedString(response.data.combinedFinalString);
        setPrompt(response.data.systemPrompt);
        setShowSaveMsgButton(true);
        setOutput(dataa.choices[0].message.content);
        setCreditUsed(creditUsed+1)
      }
    ).catch(
      (error) => {
        //setExecuteMessage(error);
        console.log("error", error);
      }
    );
    setShowModal(false);
        
  }

  const loadOptions = (inputValue) => {
    if (!inputValue) return Promise.resolve([]);
    return ProductService.searchAndSelectISBN(inputValue, organizationID).then(
      (response) => {
        return response.productsisbns.map(item => 
          ({ 
            label: item.product_isbn, 
            value: item.product_isbn 
          })
        )
        //console.log(response)
      }
    ).catch(
      (error) => {
        console.log("error", error)
      }
    );
    
    
  };
  //console.log("answerData", answerData)
  

  const HelpBubble = ({ text }) => {
    const [isTooltipVisible, setTooltipVisible] = useState(false);
    const bubbleRef = useRef(null);

    const toggleTooltip = () => {
      setTooltipVisible(!isTooltipVisible);
    };

    // Close tooltip when clicking outside
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (bubbleRef.current && !bubbleRef.current.contains(event.target)) {
          setTooltipVisible(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    return (
      <div className="help-container" ref={bubbleRef}>
        <img className="help-bubble" onClick={toggleTooltip}  src={Information} />
        {isTooltipVisible && <div className="tooltip">{text}</div>}
      </div>
    );
  };

  const nl2br = (text) => {
    return text.split('\n').map((str, index) => (
      <>
        {str}
        <br />
      </>
    ));
  };
  //start
	return (
        <div  >
            <div className="container-fluid">
              <div className="row">
                

                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap d-block align-items-center pt-3 pb-2 mb-3">
                      <button className="navbar-toggler bg-light position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="bi bi-list"></i>
                          </button>
                    </div>
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3  mb-1">
                    <div className="col-md-3 col-lg-7 px-4 ">
                      <h1 className="h1">{t('improvethejobdescription')}</h1>
                      <div className="breadcrumb">
                        <Link to={"/"+appslug+"/job-description"} >{t('jobdescriptionforseoassistant')}</Link>&nbsp;&raquo; {t('improvethejobdescription')}
                      </div>
                    </div>
                    <CreditBar creditUsed={creditUsed} totalCredit={totalCredit} /><TopBar />
                  </div>
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-0 ">
                    <div className="col-md-3 col-lg-8 px-4 ">
                    <p>{t('improvementofjobdescriptionpagesubline')}</p>
                    </div>
                  </div>
                  
                  <div className="container-fluid ">
                    <div className="row">
                        {questionsList ? questionsList.map((values, keys) => {
                          return <>
                            <div className="col-12 col-lg-6 p-4">
                              <div className="col-12 job_desc_question_label">
                                <table className="table table-hover align-middle">
                                <tbody>
                                  <tr className="tr-header">
                                  <td>{values.question}  <HelpBubble text={values.help} /></td>
                                  <td align="right">
                                  <button className="btn fm-btn" onClick={() => addRow(values.id) } type="button">{t('add')}</button></td>
                                  </tr>
                                 </tbody>
                                </table> 
                              </div>
                              <div className="col-12">
                                <div className="responsive-container" id={values.id}>
                                  {answerData[values.id] ? (
                                    answerData[values.id].map((value, key) => (
                                      <div key={value.id} className={`row align-items-center ${value.id}`}>
                                        {values.type === "twocolumn" && (
                                          <div className=" col-11 col-lg-3">
                                            <input
                                              type="text"
                                              name={`answer_heading_${value.id}`}
                                              className="form-control"
                                              placeholder=""
                                              value={value.answer_heading}
                                              onChange={(e) => {
                                                setAnswerData((prevData) => {
                                                  const updatedAnswers = [...prevData[values.id]];
                                                  updatedAnswers[key].answer_heading = e.target.value;
                                                  return {
                                                    ...prevData,
                                                    [values.id]: updatedAnswers,
                                                  };
                                                });
                                              }}
                                            />
                                          </div>
                                        )}
                                        <div className={values.type === "twocolumn" ? "col-11 col-lg-8" : "col-11"}>
                                          <input
                                            type="text"
                                            name={`answer_${value.id}`}
                                            className="form-control"
                                            placeholder=""
                                            value={value.answer}
                                            onChange={(e) => {
                                              setAnswerData((prevData) => {
                                                const updatedAnswers = [...prevData[values.id]];
                                                updatedAnswers[key].answer = e.target.value;
                                                return {
                                                  ...prevData,
                                                  [values.id]: updatedAnswers,
                                                };
                                              });
                                            }}
                                          />
                                        </div>
                                        <div className="col-1 text-end">
                                          <img
                                            className="close-btn"
                                            src={CloseCircle}
                                            title={t('remove')}
                                            onClick={() => removeRow(values.id, key)}
                                            alt="Close"
                                          />
                                        </div>
                                      </div>
                                    ))
                                  ) : (
                                    ''
                                  )}
                                </div>

                                <table className="table d-none table-hover align-middle" id={values.id}>
                                  <tbody>
                                  {answerData[values.id] ? answerData[values.id].map((value, key) => {
                                    return <>
                                      <tr key={value.id} className={value.id}>
                                        {values.type=="twocolumn" ?
                                        <td className="col-3">
                                          <input
                                            type="text"
                                            name={`answer_heading_${value.id}`}
                                            className="form-control"
                                            placeholder=""
                                            aria-label=""
                                            aria-describedby=""
                                            value={value.answer_heading}
                                            onChange={(e) => {
                                              setAnswerData((prevData) => {
                                                const updatedAnswers = [...prevData[values.id]];
                                                updatedAnswers[key].answer_heading = e.target.value;
                                                return {
                                                  ...prevData,
                                                  [values.id]: updatedAnswers,
                                                };
                                              });
                                            }}
                                          />
                                        </td>
                                        : ''}
                                        <td>
                                          <input
                                            type="text"
                                            name={`answer_${value.id}`}
                                            className="form-control"
                                            placeholder=""
                                            aria-label=""
                                            aria-describedby=""
                                            value={value.answer}
                                            onChange={(e) => {
                                              setAnswerData((prevData) => {
                                                const updatedAnswers = [...prevData[values.id]];
                                                updatedAnswers[key].answer = e.target.value;
                                                return {
                                                  ...prevData,
                                                  [values.id]: updatedAnswers,
                                                };
                                              });
                                            }}
                                          />
                                        </td>
                                        <td align="right" className="col-1">
                                          <img
                                            className="close-btn"
                                            src={CloseCircle}
                                            title={t('remove')}
                                            onClick={() => removeRow(values.id, key)}
                                            alt="Close"
                                          />
                                        </td>
                                      </tr>
                                    </>
                                  }) : ''}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </>
                        }) : ''}  
                          
                          
                        <div className="col-12 p-4">
                          <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                            {showTestPopUpButton ? (<>
                            <button className="btn btn-m fm-btn me-md-2 px-5" type="button" onClick={openTestPopUp}>{t('testimprovement')}</button>
                            </>) : ''}
                            
                          </div>
                        </div>
                        <div className="col-12 p-4">
                          <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                            
                          </div>
                        </div>
                          
                      
                         
                          
                          <div className="col-12 fm-content-box pt-4">
                              <div className="col-12">
                                <table width="100%" className=" align-middle">
                                 <tbody>
                                    {/* window.location.origin=="https://dr-beta.virtualassistants.no" || window.location.origin=="http://dr-beta.virtualassistants.no:3000" || window.location.origin=="http://localhost:3000" ?  */}
                                    {window.location.origin=="http://localhost:3000" || window.location.origin=="http://dr-beta.virtualassistants.no:3000" ? 
                                    (<>
                                    <tr>
                                      <td><h3>{t('prompt')}</h3></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {systemdefinedString ? <SystemPrompt text={systemdefinedString} /> : ''} 
                                      </td>
                                    </tr>
                                    
                                    <tr>
                                      <td>
                                        {userPromptMessage ? <SystemPrompt text={userPromptMessage} /> : ''} 
                                      </td>
                                    </tr>
                                    <tr>
                                      <td><br /><hr /><br /></td>
                                    </tr>
                                    </>) : "" }
                                    <tr>
                                      <td><h3>{t('description')}</h3></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {productDetails ? <SystemPrompt text={productDetails} /> : ''} 
                                      </td>
                                    </tr>
                                    <tr>
                                      <td><br /><hr /><br /></td>
                                    </tr>
                                    <tr>
                                      <td><h3>{t('output')}</h3></td>
                                    </tr>
                                    <tr>
                                      <td>{executeMessage ? ( <> {executeMessage} { showSaveMsgButton ? (<><br /> 
                                        <button  className="btn btn-m fm-btn me-md-2 px-5" type="button" onClick={saveOutputHistory}>{t('saveseooptimizedtextfortraining')}</button>
                                        <button className="btn btn-m fm-btn" onClick={() => saveJobDescription()} type="button">{t('saveimprovement')}</button>  {savePromptMsg ? savePromptMsg : ''}
                                        </> ) : '' }</> ):''} 
                                       {outputSavedMsg ? outputSavedMsg : '' } <br /><br />
                                      

                                      
                                      </td>
                                    </tr>
                                     </tbody>
                                  </table>
                              </div>
                            </div>
                        
                        
                     </div>
                     
                  </div>
                  {showModal ? 
                    <div className="modal display-block">
                      <div className="modal-main">
                        <div style={{float: 'right', marginTop: '-15px', marginRight: '-5px'}}>
                          <img src={CloseIcon} onClick={handleClose} className="close-btn" width="12" />
                        </div>
                        <div className="modal-contentt">
                          <div className="col-12 p-4">
                            <div className="col-12 job_desc_question_label">{t('products')}</div>
                            <div className="col-12">
                              <div className="mb-3">
                                <AsyncSelect
                                  cacheOptions
                                  loadOptions={loadOptions}
                                  onChange={handleChange}
                                  defaultOptions
                                  placeholder="Search and select..."
                                />
                                
                              </div>
                            </div>
                          </div>
                          <div className="col-12 p-4">
                            <div className="col-12">
                              <button className="btn btn-m fm-btn me-md-2 px-5" type="button" onClick={handleGenerateSEOSubmit}>{t('testimprovement')}</button>
                            </div>
                          </div>  
                        </div>
                      </div>
                    </div>
                  : ''}
                  <div className=" pb-4">
                  </div>  
                </main>
              </div>
            </div>
        	
        </div>
        )
}
export default ImproveJob;
import axios from "axios";

class ProductService {
    
    
    productList(assistantID, action, serachISBN, page, orgID) {
        
        const options = {
            method: "GET",
            headers: {"Content-Type": "application/json"},
            url: process.env.REACT_APP_API_SERVER_URL + "products?page="+page+"&action="+action+"&assistant_id="+assistantID+"&searchisbn="+serachISBN+"&organization_id="+orgID,
        } 
        return axios(options)
        .then(response => {
           return response;
        })

    }

    
     searchAndSelectISBN(query, orgID) {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"query":query, "organization_id": orgID},
            url: process.env.REACT_APP_API_SERVER_URL + "productsisbn"
        } 
        return axios(options)
        .then(response => {
            return response.data
        })

        

    }

    ProductStats(assistantID, orgID) {
        
        const options = {
            method: "GET",
            headers: {"Content-Type": "application/json"},
            url: process.env.REACT_APP_API_SERVER_URL + "productsstats?assistant_id="+assistantID+"&organization_id="+orgID,
        } 
        return axios(options)
        .then(response => {
           return response;
        })

    }


    outputList(assistantID,  isbn, action, orgID) {
        
        const options = {
            method: "GET",
            headers: {"Content-Type": "application/json"},
            url: process.env.REACT_APP_API_SERVER_URL + "outputs?isbn="+isbn+"&assistant_id="+assistantID+"&action="+action+"&organization_id="+orgID,
        } 
        return axios(options)
        .then(response => {
           return response;
        })

    }

    exampleList(assistantID, orgID) {
        
        const options = {
            method: "GET",
            headers: {"Content-Type": "application/json"},
            url: process.env.REACT_APP_API_SERVER_URL + "examplelist?assistant_id="+assistantID+"&organization_id="+orgID,
        } 
        return axios(options)
        .then(response => {
           return response;
        })

    }

    deleteOutput(outputId){
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"id":outputId},
            url: process.env.REACT_APP_API_SERVER_URL + "deleteoutput",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }

    exampleOutput(assistantID, updown, outputId){
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"id":outputId, "updown": updown, "assistant_id": assistantID},
            url: process.env.REACT_APP_API_SERVER_URL + "exampleoutput",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }

    addSchedule(orgID, assistantID, selectedDate, isbnlist, schedulenow){
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"isbns": isbnlist, "assistant_id": assistantID, "cron_start_date": selectedDate, "organization_id": orgID, "schedulenow": schedulenow},
            url: process.env.REACT_APP_API_SERVER_URL + "addschedule",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }

    setAutoMunualApprove(assistantID, orgID, autoManual){
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"assistant_id": assistantID, "organization_id": orgID, "move_to_feed": autoManual},
            url: process.env.REACT_APP_API_SERVER_URL + "setautoapprove",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }

    saveManualOutputID(assistantID, orgID, outputID){
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"assistant_id": assistantID, "organization_id": orgID, "output_id": outputID},
            url: process.env.REACT_APP_API_SERVER_URL + "savemanualoutputid",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }
    
    OutputIDSavedForPush(assistantID, orgID){
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"assistant_id": assistantID, "organization_id": orgID},
            url: process.env.REACT_APP_API_SERVER_URL + "getoutputidsavedforpush",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }
    
}

export default new ProductService();
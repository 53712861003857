import {NavLink, useNavigate, useParams  } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import AuthService from "../services/AuthService";
import AssistantService from "../services/AssistantService";
import PromptService from "../services/PromptService";
import "../styles/bootstrap.min.css";
import CreditBar from "./CreditBar";
import TopBar from "./Topbar";
import SideBar from "./Sidebar";
import JobDescriptionQuestion from "./JobDescriptionQuestion";
import UserAvatar  from "../images/user-avatar.png";
import OurValues  from "../images/our-values.png";
import ToneOfVoice  from "../images/tone-voice.png";
import Language  from "../images/language.png";
import CloseIcon  from "../images/close.png";
import JobResult  from "../images/job-result.png";
import Feedback  from "../images/feedback.png";
import Notification  from "../images/notifications_none.png";
import Theme  from "../images/moon-solid.png";
import Information  from "../images/info_outline.png";
import { useTranslation } from 'react-i18next';
import CloseCircle  from "../images/cross-circle.png";

function JobDescription() {
  const { appslug } = useParams();
  //console.log(appslug)
  document.body.style.backgroundColor = '#FFFFFF';
  const { t } = useTranslation();
  const [assistantID, setAssistantID] = useState("");
  const [organizationID, setOrganizationID] = useState(parseInt(window.sessionStorage.getItem('organization_id'), 10));
  const [Q1Data, setQ1Data] = useState("");
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpModalCotent, setHelpModalCotent] = useState("");
  const [totalCredit, setTotalCredit] = useState();
  const [creditUsed, setCreditUsed] = useState();
  const [questionsList, setQuestionsList] = useState();
  const [answerData, setAnswerData] = useState([]);
  
  useEffect(() => {
    AssistantService.getAssistantIDBySlug(appslug, organizationID).then(
      (assistresponse) => {

        PromptService.getAssistantQuestions(assistresponse.assistantID).then(
          (response) => {
            //console.log("response", response);
            setQuestionsList(response.questions)
            response.questions.forEach(question => {
              console.log("question", question.id)
              fetchAnswers(question.id)
            });
          }
        ). catch(
          (error) => {
            console.log("Error fetching getAssistantQuestions", error);
          }
        )
        
        //setAssistantID(response.assistantID);
      }
    )
    
    AssistantService.getAssistantIDBySlug(appslug, organizationID).then(
      (assistresponse) => {
        setTotalCredit(assistresponse.credits)
        setCreditUsed(assistresponse.consumed)
      }
    )
    
    
  },[appslug])
  
  const fetchAnswers = async (questionID) => {
    try {
      PromptService.getAnswersByQuestion(questionID, organizationID).then(
      (response) => {
        //console.log(response.data);
        setAnswerData(prevData => ({ ...prevData, [questionID]: response.answers }));
      },
      (error) => {
        console.log(error);
      });
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const HelpBubble = ({ text }) => {
    const [isTooltipVisible, setTooltipVisible] = useState(false);
    const bubbleRef = useRef(null);

    const toggleTooltip = () => {
      setTooltipVisible(!isTooltipVisible);
    };

    // Close tooltip when clicking outside
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (bubbleRef.current && !bubbleRef.current.contains(event.target)) {
          setTooltipVisible(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    return (
      <div className="help-container" ref={bubbleRef}>
        <img className="help-bubble" onClick={toggleTooltip}  src={Information} />
        {isTooltipVisible && <div className="tooltip">{text}</div>}
      </div>
    );
  };

  return (
        <div  >
            <div className="container-fluid">
              <div className="row">
                

                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap d-lg-none d-block align-items-center pt-3 pb-2 mb-3">
                      <button className="navbar-toggler bg-light position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="bi bi-list"></i>
                          </button>
                    </div>
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-1">
                    <div className="col-md-3 col-lg-7 px-4 ">
                      <h1 className="h1">{t('jobdescriptionforseoassistant')}</h1>
                      <div className="breadcrumb">
                        {t('jobdescriptionforseoassistant')}
                      </div>
                    </div>
                    <CreditBar  creditUsed={creditUsed} totalCredit={totalCredit} /><TopBar />
                  </div>
                  
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-0 mb-3">
                    <div className="col-md-3 col-lg-8 px-4 ">
                    <p>{t('jobdescriptionpagesubline')}</p>
                    </div>
                  </div>
                   
                  <div className="container-fluid mt-4">
                    <div className="row">

                          <div className="col-12 p-4">
                            {questionsList ? questionsList.map((values, keys) => {
                              return <>
                                <div className="col-12 job_desc_question_label">
                                  <br /><table className="table table-hover pb-0 align-middle">
                                    <tbody>
                                      <tr className="tr-header">
                                      <td width="auto">
                                        {values.question}  <HelpBubble text={values.help} />
                                      </td>
                                      <td align="left"></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="col-12">
                                  <div className="responsive-container">
                                    {answerData[values.id] ? (
                                      answerData[values.id].map((value, key) => (
                                        <div key={key} className="row align-items-center">
                                          {values.type === "twocolumn" && (
                                            <div className="col-12 col-lg-2">
                                              <input
                                                readOnly
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                value={value.answer_heading}
                                              />
                                            </div>
                                          )}
                                          <div className={values.type === "twocolumn" ? "col-12 col-lg-10" : "col-12"}>
                                            <input
                                              readOnly
                                              type="text"
                                              className="form-control"
                                              placeholder=""
                                              value={value.answer}
                                            />
                                          </div>
                                        </div>
                                      ))
                                    ) : (
                                      <>
                                        <br />
                                        <br />
                                      </>
                                    )}
                                  </div>

                                  
                                </div>
                                </>
                            }) : ''}

                            
                            

                         </div>
                          
                         
                      
                         <div className="col-12 p-4">
                          <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                              <NavLink to={"/"+appslug+"/history"}><button className="btn btn-lg fm-btn me-md-2" type="button" >{t('previousjobdescription')}</button> </NavLink> 
                              <NavLink to={"/"+appslug+"/improve"}><button className="btn btn-lg fm-btn" type="button">{t('improvethejobdescription')}</button></NavLink> 
                            </div>
                         </div>
                      
                        
                        
                     </div>
                     
                  </div>
                  
                  
                </main>
              </div>
            </div>
          
        </div>
        )
}
export default JobDescription;
import axios from "axios";

class PromptService {
    
    savePrompt(assistantID, type, prompt, organizationID) {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                    "prompt_type": type,
                    "prompt": JSON.stringify(prompt),
                    "assistant_id": assistantID,
                    "organization_id": organizationID
                
            },
            url: process.env.REACT_APP_API_SERVER_URL + "saveprompt",
        } 
        return axios(options)
            .then(response => {
                return response.data
            })
    }

    getAssistantQuestions(assistantID) {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"assistant_id":assistantID},
            url: process.env.REACT_APP_API_SERVER_URL + "assistantquestions"
        } 
        return axios(options)
        .then(response => {
            return response.data
        })

        

    }

    editAssistantQuestion(assistantID, quesiton, help, questionID) {
        //return assistantID+' - '+prompt+' - '+indexKey+' - '+column;
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                "question": quesiton,
                "help": help,
                "assistant_id": assistantID,
                "id": questionID
                
            },
            url: process.env.REACT_APP_API_SERVER_URL + "editassistantquestion",
        } 
        return axios(options)
            .then(response => {
                return response.data
            })
    }

    addAssistantQuestion(assistantID, quesiton, help, type) {
        //return assistantID+' - '+prompt+' - '+indexKey+' - '+column;
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                "question": quesiton,
                "help": help,
                "assistant_id": assistantID,
                "type": type,
                
            },
            url: process.env.REACT_APP_API_SERVER_URL + "addassistantquestion",
        } 
        return axios(options)
            .then(response => {
                return response.data
            })
    }

    deleteAssistantQuestion(questionID) {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                "id": questionID
                
            },
            url: process.env.REACT_APP_API_SERVER_URL + "deleteassistantquestion",
        } 
        return axios(options)
            .then(response => {
                return response.data
            })
    }

    getAnswersByQuestion(questionID, organizationID) {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"question_id":questionID, "organization_id":organizationID},
            url: process.env.REACT_APP_API_SERVER_URL + "answersbyquestion"
        } 
        return axios(options)
        .then(response => {
            return response.data
        })

        

    }

    updateAnswers(assistantID, organizationID, answerData, questionsList) {
        const answerArray = Object.entries(answerData).map(([key, value]) => ({
          questionId: Number(key), // Convert key back to a number if necessary
          answers: value,          // Retain the array of answers
        }));
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                "assistant_id":assistantID, 
                "organization_id":organizationID,
                "answerdata":JSON.stringify(answerArray),
                "questionslist":JSON.stringify(questionsList)
            },
            url: process.env.REACT_APP_API_SERVER_URL + "updateanswers"
        } 
        return axios(options)
        .then(response => {
            return response.data
        })

        

    }


    addSystemDefinedPrompt(organizationID, assistantID, prompt) {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                "prompt": JSON.stringify(prompt),
                "assistant_id": assistantID,
                "organization_id": organizationID
                
            },
            url: process.env.REACT_APP_API_SERVER_URL + "addsystemprompt",
        } 
        return axios(options)
            .then(response => {
                return response.data
            })
    }

    editSystemDefinedPrompt(organizationID, assistantID, prompt, indexKey) {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                "index_key": indexKey,
                "prompt": JSON.stringify(prompt),
                "assistant_id": assistantID,
                "organization_id": organizationID
                
            },
            url: process.env.REACT_APP_API_SERVER_URL + "editsystemprompt",
        } 
        return axios(options)
            .then(response => {
                return response.data
            })
    }
    editUserOneDefinedPrompt(organizationID, assistantID, prompt, indexKey, column) {
        //return assistantID+' - '+prompt+' - '+indexKey+' - '+column;
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                "index_key": indexKey,
                "prompt": JSON.stringify(prompt),
                "assistant_id": assistantID,
                "organization_id": organizationID,
                "column": column
                
            },
            url: process.env.REACT_APP_API_SERVER_URL + "edituseroneprompt",
        } 
        return axios(options)
            .then(response => {
                return response.data
            })
    }
    addUserOneDefinedPrompt(organizationID, assistantID, prompt, column) {
        //return assistantID+' - '+prompt+' - '+indexKey+' - '+column;
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                "prompt": JSON.stringify(prompt),
                "assistant_id": assistantID,
                "organization_id": organizationID,
                "column": column
                
            },
            url: process.env.REACT_APP_API_SERVER_URL + "adduseroneprompt",
        } 
        return axios(options)
            .then(response => {
                return response.data
            })
    }
    deleteSystemDefinedPrompt(assistantID, indexKey) {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                "index_key": indexKey,
                "assistant_id": assistantID
                
            },
            url: process.env.REACT_APP_API_SERVER_URL + "deletesystempromptquestion",
        } 
        return axios(options)
            .then(response => {
                return response.data
            })
    }

    deleteUserOnePrompt(assistantID, indexKey, column) {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                "index_key": indexKey,
                "assistant_id": assistantID,
                "column": column
                
            },
            url: process.env.REACT_APP_API_SERVER_URL + "deleteuseronepromptquestion",
        } 
        return axios(options)
            .then(response => {
                return response.data
            })
    }

    saveTempPrompt(assistantID, orgID, type, prompt) {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                    "prompt_type": type,
                    "prompt": JSON.stringify(prompt),
                    "assistant_id": assistantID,
                    "organization_id": orgID
                
            },
            url: process.env.REACT_APP_API_SERVER_URL + "savetempprompt",
        } 
        return axios(options)
            .then(response => {
                return response.data
            })
    }

    getPromptByType(assistantID, type, organizationID) {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"prompt_type": type, "assistant_id": assistantID, "organization_id": organizationID},
            url: process.env.REACT_APP_API_SERVER_URL + "getprompt",
        } 
        return axios(options)
            .then(response => {
                return response.data
            })
    }

    getPromptByAssisstant(assistantID) {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"assistant_id": assistantID},
            url: process.env.REACT_APP_API_SERVER_URL + "promptlist",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }

    promptHistory(assistantID, orgID, page){
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"assistant_id": assistantID, "organization_id": orgID, "page_number": page},
            url: process.env.REACT_APP_API_SERVER_URL + "prompthistory",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }


    fetchGroupData(assistantID, orgID, groupID){
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"assistant_id": assistantID, "organization_id": orgID, "group": groupID},
            url: process.env.REACT_APP_API_SERVER_URL + "promptgroupdata",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }
    promptRestore(groupID, assistantID, orgID){
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"group": groupID, "assistant_id": assistantID, "organization_id": orgID},
            url: process.env.REACT_APP_API_SERVER_URL + "promptrestore",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }

    
    

}

export default new PromptService();